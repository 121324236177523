require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
// window.Vue = require('vue');
import Vue from "vue/dist/vue.js";

window.axios = require('axios').default;
window._ = require('lodash/core');
import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)

let app = new Vue({
  el: '#app',
  props: {
    // language: String
  },
  data: {
    menuShown: false,
    closeClass: '',
    hintMessage: '...',
    hintClass: 'hidden',
    drinks: [],
    findDrinkInput: '',


  },
  methods: {
    toggleMenu() {
      this.menuShown = !this.menuShown;
    },
    closeMenu() {
      this.closeClass = 'animate__slideOutUp';
      setTimeout(() => {
        this.closeClass = '';
        this.menuShown = false;
      }, 800);
    },
    findDrinks() {
      let input;
      if (this.findDrinkInput === '') {
        input = '><';
      } else {
        input = this.findDrinkInput;
      }
      axios.post('/api/drinks', {
        inputText: input,
      }).then(response => this.drinks = response.data);

    }
  },
  mounted() {
    let i34 = document.getElementById("34");
    let i35 = document.getElementById("35");
    let i36 = document.getElementById("36");
    let i38 = document.getElementById("38");
    let i40 = document.getElementById("40");
    let i42 = document.getElementById("42");
    let i43 = document.getElementById("43");
    window.addEventListener('scroll', () => {
      if (window.scrollY > 70) {
        this.hintClass = '';
      } else {
        this.hintClass = 'hidden';
      }
      if (i34 !== null) {
        if (window.scrollY >= i34.offsetTop - 70) {
          this.hintMessage = i34.innerText;
        }

        if (window.scrollY >= i35.offsetTop - 70) {
          this.hintMessage = i35.innerText;
        }
        if (window.scrollY >= i36.offsetTop - 70) {
          this.hintMessage = i36.innerText;
        }

        if (window.scrollY >= i38.offsetTop - 70) {
          this.hintMessage = i38.innerText;
        }

        if (window.scrollY >= i40.offsetTop - 70) {
          this.hintMessage = i40.innerText;
        }
        if (window.scrollY >= i42.offsetTop - 70) {
          this.hintMessage = i42.innerText;
        }
        if (window.scrollY >= i43.offsetTop - 70) {
          this.hintMessage = i43.innerText;
        }
      }

    });
  }

});

Vue.config.devtools = false
Vue.config.debug = false
Vue.config.silent = true

